import React, { Component } from "react"
import classNames from "classnames"
import { pure, compose } from "recompose"
import Layout from "../components/layout"
import { withAssetPrefix, graphql, Link, navigate } from "gatsby"
import SEO from "../components/seo"

import { withStyles } from "@material-ui/core/styles"

const noImage = "/assets/images/noImage.jpg"
const titleLimitByte = 57
const authorLimitByte = 78

type Props = {
  classes: any
  pageContext: any
}

type State = {
  checked: boolean
}

type WPPost = {
  node: {
    id: string
    title: string
    content: string
    date: string
    status: string
    excerpt: string
    featured_media: {
      source_url: string | null
    }
    acf: {
      author: string | null
    }
  }
}

class NewsList extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      checked: false,
    }
  }

  _formatDate(wordpressDate: string) {
    const dateObj = new Date(wordpressDate)
    return (
      dateObj.getFullYear() +
      "." +
      (dateObj.getMonth() + 1) +
      "." +
      dateObj.getDate()
    )
  }

  _handleOnClick = (id: string) => (event: any) => {
    navigate(`/stories/${id}`)
  }

  _zeroPadding(index: int) {
    return index < 10 ? "0" + (index + 1) : index + 1
  }

  _cutTextLimitOver(text: string, limit: int) {
    console.log(text.length)
    return text.length > limit ? text.slice(0, limit - 1) + "..." : text
  }

  render() {
    const { classes } = this.props
    const newsList = this.props.pageContext.newsList.edges

    return (
      <Layout>
        <SEO title="OUR STORIES一覧" />
        <div className={classes.root}>
          <div className={classes.breadCrumb}>
            <Link to={`/`}>
              TOP
            </Link>
            <span className={classes.breadCrumbSplit}> > </span>OUR STORIES一覧
          </div>
          <div className={classes.content}>
            <div className={classes.itemList}>
              {newsList.map(({ node }: WPPost, index: number) => {
                return (
                  <div
                    className={classes.itemBox}
                    onClick={this._handleOnClick(node.id)}
                  >
                    <div className={classes.storyNumber}>
                      {this._zeroPadding(index)}
                    </div>
                    <div className={classes.date}>
                      {this._formatDate(node.date)}
                    </div>
                    <div className={classes.title}>
                      {this._cutTextLimitOver(node.title, titleLimitByte)}
                    </div>
                    <div className={classes.author}>
                      {node.acf && node.acf.author
                        ? this._cutTextLimitOver(
                            node.acf.author,
                            authorLimitByte
                          )
                        : ""}
                    </div>
                    <div>
                      <img
                        className={classes.img}
                        src={
                          node.featured_media && node.featured_media.source_url
                            ? node.featured_media.source_url
                            : withAssetPrefix(noImage)
                        }
                        alt={node.title}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    padding: "40px 80px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px",
    },
  },
  breadCrumb: {
    fontFamily:
      '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    fontSize: 18,
    marginBottom: 40,
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      marginBottom: 30,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
      marginBottom: 10,
    },
  },
  breadCrumbSplit: {
    fontFamily: "Advent Pro",
    fontWeight: "bold",
    marginLeft: 10,
    marginRight: 10,
    color: "#999",
  },
  content: {
    textAlign: "center",
  },
  itemList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  itemBox: {
    backgroundColor: "white",
    margin: "20px 20px",
    padding: "10px 20px",
    width: 430,
    height: 610,
    borderRadius: "7px",
    [theme.breakpoints.down("sm")]: {
      width: 300,
      height: 480,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      height: 390,
    },
  },
  storyNumber: {
    fontFamily:
      '"Advent Pro", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    fontSize: 90,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: 3.0,
    marginBottom: 60,
    marginTop: 40,
    textAlign: "left",
    color: "#ccc",
    [theme.breakpoints.down("sm")]: {
      fontSize: 60,
      marginBottom: 40,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 60,
      marginBottom: 20,
      marginTop: 30,
    },
  },
  title: {
    fontFamily:
      '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    fontSize: 20,
    marginBottom: 20,
    height: 70,
    textAlign: "right",
    lineHeight: "22px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      lineHeight: "19px",
      marginBottom: 15,
      height: 50,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
      lineHeight: "17px",
      marginBottom: 10,
      height: 40,
    },
  },
  date: {
    fontFamily: "Advent Pro",
    fontStyle: "normal",
    fontStretch: "normal",
    color: "#ccc",
    fontSize: 25,
    letterSpacing: 1.1,
    marginBottom: "20px",
    textAlign: "right",
    height: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: 23,
      marginBottom: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      marginBottom: "5px",
    },
  },
  author: {
    fontFamily:
      '"Noto Sans JP", -apple-system, BlinkMacSystemFont, "Helvetica Neue", YuGothic, "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif',
    fontSize: 15,
    marginBottom: 20,
    height: 60,
    textAlign: "right",
    lineHeight: "17px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
      lineHeight: "15px",
      height: 40,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
      lineHeight: "12px",
      height: 20,
    },
  },
  img: {
    width: "410px",
    height: "230px",
    objectFit: "cover",
    marginBottom: 0,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "320px",
      height: "170px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px",
      height: "160px",
    },
  },
})

export default compose(withStyles(styles))(NewsList)
